import "bootstrap/dist/css/bootstrap.min.css"
import "./src/styles/global.css"

import React from 'react';
import { AnimatePresence } from "framer-motion";
// import { TransitionState } from "gatsby-plugin-transition-link";

export const wrapPageElement = ({ element }) => {
  return (
    <AnimatePresence
      className="hello"
      //initial={false}
      exitBeforeEnter
    >
      {element}
    </AnimatePresence>
    // <TransitionState>
    //   {({ transitionStatus, entry, exit }) => (
    //     { element }
    //   )}
    // </TransitionState>
  );
}

export const shouldUpdateScroll = () => {
  return false
}

export const onInitialClientRender = () => {
  window.___GATSBYGRAM_INITIAL_RENDER_COMPLETE = true
}